import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          // d="M40.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
          d="M110.7 117.03L85.59 83.64c13.01-5.37 22.18-18.08 22.18-32.87c0-19.63-16.13-35.61-35.96-35.61H33.95c-.15 0-.28.06-.42.09h-6.46c-1.24 0-2.24 1-2.24 2.24v100.96c0 1.24 1 2.24 2.24 2.24h21.89c1.24 0 2.24-1 2.24-2.24V86.37h8.64l25.2 33.51c.45.59 1.15.94 1.89.94h21.89c.9 0 1.72-.51 2.11-1.31c.4-.8.31-1.76-.23-2.48zM70.12 65.38c-.36.03-.72.06-1.08.06H51.57c-.05 0-.09-.04-.14-.06c-.09-.02-.18-.04-.24-.1a.577.577 0 0 1-.21-.43V36.67c0-.17.08-.32.21-.43c.06-.05.14-.07.22-.09c.06-.02.1-.07.16-.07h17.46c.42 0 .84.03 1.25.07c7.22.66 12.9 6.96 12.9 14.61c.01 7.72-5.76 14.04-13.06 14.62z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;

// <svg
// width="101px"
// height="101px"
//   viewBox="-55.04 -55.04 238.08 238.08"
//   xmlns="http://www.w3.org/2000/svg"
//   xmlns:xlink="http://www.w3.org/1999/xlink"
//   aria-hidden="true"
//   role="img"
//   class="iconify iconify--noto"
//   preserveAspectRatio="xMidYMid meet"
//   fill="#000000"
//   stroke="#000000"
//   stroke-width="0.00128">
//   <g id="SVGRepo_bgCarrier" stroke-width="0">
//     <path
//       transform="translate(-55.04, -55.04), scale(14.88)"
//       fill="#7ed0ec"
//       d="M9.166.33a2.25 2.25 0 00-2.332 0l-5.25 3.182A2.25 2.25 0 00.5 5.436v5.128a2.25 2.25 0 001.084 1.924l5.25 3.182a2.25 2.25 0 002.332 0l5.25-3.182a2.25 2.25 0 001.084-1.924V5.436a2.25 2.25 0 00-1.084-1.924L9.166.33z"
//       strokewidth="0"></path>
//   </g>
//   <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
//   <g id="SVGRepo_iconCarrier">
//     <path
//       d="M110.7 117.03L85.59 83.64c13.01-5.37 22.18-18.08 22.18-32.87c0-19.63-16.13-35.61-35.96-35.61H33.95c-.15 0-.28.06-.42.09h-6.46c-1.24 0-2.24 1-2.24 2.24v100.96c0 1.24 1 2.24 2.24 2.24h21.89c1.24 0 2.24-1 2.24-2.24V86.37h8.64l25.2 33.51c.45.59 1.15.94 1.89.94h21.89c.9 0 1.72-.51 2.11-1.31c.4-.8.31-1.76-.23-2.48zM70.12 65.38c-.36.03-.72.06-1.08.06H51.57c-.05 0-.09-.04-.14-.06c-.09-.02-.18-.04-.24-.1a.577.577 0 0 1-.21-.43V36.67c0-.17.08-.32.21-.43c.06-.05.14-.07.22-.09c.06-.02.1-.07.16-.07h17.46c.42 0 .84.03 1.25.07c7.22.66 12.9 6.96 12.9 14.61c.01 7.72-5.76 14.04-13.06 14.62z"
//       fill="#40C0E7"></path>
//   </g>
// </svg>;
